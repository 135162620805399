import React, { useState } from 'react';
import './header.scss';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/woordmerk.svg';
import Headroom from 'react-headroom';

interface IProps {
  handleEnglish: any;
}


function Header(props: IProps) {
  const [isEnglish, setIsEnglish] = useState(false);

  const HandleClick = () => {
    const varIsEnglish = !isEnglish;
    setIsEnglish(varIsEnglish);
    props.handleEnglish();
  }

  return (
    <Headroom disable={false} pinStart={0} disableInlineStyles={true}>
      <nav className={`navbar navbar-expand-lg smart-scroll`}>
        <div className='container'>
          <Link to='/projects'>
            <img src={logo} alt='Logo' />
          </Link>
          <ul className='nav navbar-nav'>
            <li className='nav-item'>
              <NavLink
                to='/projects'
                className={({ isActive }) =>
            isActive ? 'nav-link inactive active' : 'nav-link inactive'
          }
              >
                {` ${isEnglish ? 'Work' : 'Werk'}`}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/about'
                className={({ isActive }) =>
            isActive ? 'nav-link inactive active' : 'nav-link inactive'
          }
              >
                {` ${isEnglish ? 'Profile' : 'Profiel'}`}
              </NavLink>
            </li>
            <li className='nav-item'>
              <ul className='nav navbar-nav'>
                <div
                  className={`le smaller ${
                    isEnglish ? '' : 'active'
                  }`}
                  onClick={() => {HandleClick()}}
                >
                  nl
                </div>
                <div className='divider'>/</div>
                <div
                  className={`rig smaller ${
                    isEnglish ? 'active' : ''
                  }`}
                  onClick={() => {HandleClick()}}
                >
                  en
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </Headroom>
  );
}
export default Header;
