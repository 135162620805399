import React from 'react';
import './project.scss';
import { Link, NavLink } from 'react-router-dom';
import altImage from '../../assets/grey.svg';
import LazyImage from '../../components/lazyImage/LazyImage';

interface IProps {
  isEnglish: boolean;
}
interface IState {
  isLoaded: boolean;
  visible: boolean;
  error: any;
  items: any;
}
export default class Project extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoaded: false,
      visible: false,
      error: null,
      items: null,
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    fetch(
      `${process.env.REACT_APP_API_URL}getProjectsSite${process.env.REACT_APP_API_CODE}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            items: result.data,
            isLoaded: true,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            items: [],
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, items } = this.state as any;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className='project-page'>
                  <div className='row'>
        <div className='spinner-container'>
          <svg className='loader' viewBox='0 0.634 18.469 23.1'>
            <polygon points='18.468,0.634 0,0.634 0,6.262 5.805,6.262 5.805,23.734 12.664,23.734 12.664,6.263 18.469,6.263 18.469,0.634 ' />
          </svg>
        </div>
        </div>
        </div>
      );
    } else {

      const elements = items.splice(1).map((item:any, index: any) => { 
        return(
        <div className='col-lg-4 col-md-6 col-xs-12' key={index+1}>
          <NavLink to={`${item.languages.nl.name}`} state={{data:item}}>
            <LazyImage
              alt={altImage}
              src={`${process.env.REACT_APP_IMAGE_STORAGE}${item.gridImage}`}
            />
            <div className='content-image'>
              <div className='name'>
              {this.props.isEnglish ? item.languages.en.name : item.languages.nl.name}
              </div>
              <div className='line'></div>
              <div className='type'>
              {this.props.isEnglish ? item.languages.en.type : item.languages.nl.type}                </div>
            </div>
          </NavLink>
        </div>)
    });


      return(
        <div className='project-page'>
                  <div className='row'>
        <div className='col-lg-12' key={0}>
          <NavLink to={`${items[0].languages.nl.name}`} state={{data:items[0]}}>
            <LazyImage
              alt={altImage}
              src={`${process.env.REACT_APP_IMAGE_STORAGE}${items[0].gridImage}`}
            />
            <div className='content-image'>
              <div className='name'>
                {this.props.isEnglish ? items[0].languages.en.name : items[0].languages.nl.name}
              </div>
              <div className='line'></div>
              <div className='type'>
                {this.props.isEnglish ? items[0].languages.en.type : items[0].languages.nl.type}
              </div>
            </div>
          </NavLink>
        </div>
        {elements}
        </div>
        </div>
      )
    }
  }
}

