import React from 'react';
import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Header from './components/header/header';
import About from './pages/about/about';
import Project from './pages/project/project';
import Footer from './components/footer/footer';
import ProjectDetail from './pages/project-detail/project-detail';


interface IState {
  isEnglish: boolean;
}

export default class App extends React.Component<{}, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isEnglish: false,
    };
  }
  handleEnglish() {
    const switchVar = !this.state.isEnglish;
    this.setState({ isEnglish: switchVar });
    // aisEnglish = english;
  }
  render() {
    return (
      <Router>
        <div className='randomDiv'>
          <Header handleEnglish={this.handleEnglish.bind(this)} />

          <div className='main container'>
            <Routes>
              <Route  path='/projects' element={<Project isEnglish={this.state.isEnglish} />} />
              <Route path='/about' element ={<About isEnglish={this.state.isEnglish} />}/ >
              <Route path={`/projects/:projectId`} element= {<ProjectDetail isEnglish={this.state.isEnglish} />} />
              <Route path='/' element= {<Navigate to='/projects' />} />
            </Routes>
          </div>
          <Footer isEnglish={this.state.isEnglish} />
        </div>
      </Router>
    );
  }
}