import './project.scss';
import React, { useEffect, useState } from 'react';
import altImage from '../../assets/grey.svg';
import LazyImage from '../../components/lazyImage/LazyImage';
import { useParams } from 'react-router-dom';

function ProjectDetail(props) {
  let params = useParams();
  const [item, setItems] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}getProjectSite/${params.projectId}${process.env.REACT_APP_API_CODE}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result.data);
          setIsLoaded(true);
        },

        (error) => {
          setisError(true);
        }
      );
  },[null]);
  const createMarkup = (description) =>  {
        return { __html: description };
  }
 
  if (isError) {
          return (<div>Error: {errorMessage}</div>);
        } else if (!isLoaded) {
          return (
            <div className='spinner-container'>
              <svg className='loader' viewBox='0 0.634 18.469 23.1'>
                <polygon points='18.468,0.634 0,0.634 0,6.262 5.805,6.262 5.805,23.734 12.664,23.734 12.664,6.263 18.469,6.263 18.469,0.634 ' />
              </svg>
            </div>
          );
        } else {
          return (
            <div>{item !== null &&
            <div className='project-detail'>
              <div className='row'>
                <div className='col-12'>
                  <LazyImage
                    alt={altImage}
                    src={`${process.env.REACT_APP_IMAGE_STORAGE}${item.gridImage}`}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-7 col-md-8 col-sm-12 col-12'>
                  <h5>{props.isEnglish ? item.languages.en.name : item.languages.nl.name}</h5>
                  <div
                    className='project-text'
                    dangerouslySetInnerHTML={createMarkup(
                      props.isEnglish
                        ? item.languages.en.description
                        : item.languages.nl.description
                    )}
                  ></div>
                </div>
              </div>
              <br />
              <br />
              <div className='row'>
                <div className='col-lg-2 col-md-4 col-sm-4 col-4'>
                  <div className='client'>{` ${
                    props.isEnglish ? 'Client' : 'Klant'
                  }`}</div>
                  <div className='client-text'>{item.clientName}</div>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-4 col-4'>
                  <div className='services'>Type</div>
                  <div className='services-text'>
                  {props.isEnglish ? item.languages.en.type : item.languages.nl.type}
                  </div>
                </div>
                <div className='col-lg-2 col-md-4 col-sm-4 col-4'>
                  <div className='year'>{` ${
                    props.isEnglish ? 'Year' : 'Jaar'
                  }`}</div>
                  <div className='year-text'>{item.year}</div>
                </div>
              </div>
              <br />
              <br />
              <div className='row'>
                {item.images.map((value, index) => {
                  return (
                    <div className='col-12 project-images' key={index}>
                      <LazyImage
                        alt={altImage}
                        src={`${process.env.REACT_APP_IMAGE_STORAGE}${value.imageLink}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>}</div>
          );
        }
}
export default ProjectDetail;
