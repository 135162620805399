import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  @media (max-width: 400px) {
    min-height: 200px !important;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  z-index: 5000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 2s infinite;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LazyImage = (src: any, alt: any) => {
  const refPlaceholder: any = React.useRef(null);

  const removePlaceholder = () => {
    const holder: any = refPlaceholder;
    holder.current!.remove();
  };
  const isFinished = () => {
    const holder: any = refPlaceholder;
    holder.current!.remove();
  };

  return (
    <ImageWrapper>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <StyledImage
          onLoad={isFinished}
          onError={removePlaceholder}
          src={src.src}
          alt={src.alt}
        />
      </LazyLoad>
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LazyImage;
