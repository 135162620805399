import React from 'react';
import './footer.scss';

function Footer(props: any) {
  const dateYear = new Date();
  return (
    <div className='footer-container mt-5'>
      <div className='container'>
        <div className='footer'>
          <div className='row justify-content-between'>
            <div className='col-md-6 col-sm-6 col-xs-6 col-lg-4'>
              <div className='footer-text'>{` ${
                props.isEnglish ? 'Work together?' : 'Samenwerken?'
              }`}</div>
              <div className='footer-text'>
                Contact:
                &#x6A;&#x65;&#x6C;&#x6D;&#x61;&#x72;&#x40;&#x74;&#x79;&#x70;&#x65;&#x68;&#x69;&#x67;&#x68;&#x2E;&#x6E;&#x6C;
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-6 col-lg-4'>
              <br />
              <div className='text-end'>
                {' '}
                {` ${
                  props.isEnglish ? 'All content' : 'Alle content'
                }  © Typehigh ${dateYear.getFullYear()}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
