import React, { useEffect } from 'react';
import profilePhoto from '../../assets/Jelmar.jpg';
import './about.scss';

function About(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='about-page'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-6 col-xs-12 col-md-12'>
          <h3>Hello</h3>
          {!props.isEnglish && (
            <p className='profile-text'>
              Typehigh is het ontwerpbureau van Jelmar Geertsma in Groningen,
              met als specialiteiten huisstijl en identiteit ontwerp,
              typografie, letterontwerp, boekontwerp en andere vormen van
              visuele communicatie. De nadruk ligt in mijn werk altijd op
              eenvoudige maar doordachte oplossingen met een zorgvuldige
              uitwerking. Dit portfolio bevat voorbeelden van zowel commercieel
              als vrij werk. Ik ben altijd beschikbaar voor interessante
              opdrachten en bereikbaar via e-mail voor een offerte, om een
              project door te spreken of voor een afspraak.
            </p>
          )}
          {props.isEnglish && (
            <p className='profile-text'>
              Typehigh is the design agency of Jelmar Geertsma in Groningen,
              specializing in house style and identity design, typography,
              letter design, book design and other forms of visual
              communication. In my work, the emphasis is always on simple but
              well thought-out solutions with careful elaboration. This
              portfolio contains examples of both commercial and free work. I am
              always available for interesting assignments and can be reached by
              e-mail for a quote, to discuss a project or for an appointment.
            </p>
          )}
        </div>
        <div className='col-lg-2'>
          <h4 className='contact'>Contact</h4>
          <div>
            &#x6A;&#x65;&#x6C;&#x6D;&#x61;&#x72;&#x40;&#x74;&#x79;&#x70;&#x65;&#x68;&#x69;&#x67;&#x68;&#x2E;&#x6E;&#x6C;
          </div>
        </div>
        <div className='col-lg-2'>
          <h4 className='social'>Social</h4>
          <div>
            <a
              target='_blank'
              href='https://twitter.com/Jelmar'
              rel='noopener noreferrer'
            >
              Twitter
            </a>
          </div>
          <div>
            <a
              target='_blank'
              href='https://instagram.com/Jelmar'
              rel='noopener noreferrer'
            >
              Instagram
            </a>
          </div>
        </div>
      </div>

      <div className='row mt-5 mb-5'>
        <div className='col-12'>
          <img src={profilePhoto} alt='' />
        </div>
      </div>
    </div>
  );
}

export default About;
